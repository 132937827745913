import React from "react";
import styled from "styled-components";
import LayoutSection from "../components/layout/LayoutSection";
import H3 from "../components/typography/H3";
import H4 from "../components/typography/H4";
import Button from "../components/ui/Button";
import superlinarLogo from "../images/logos/superlinear.png";
import Image from "../components/image";

const Logo = styled.img`
  width: 10em;
`;

const Superlinear = styled.img`
  width: 24em;
  max-width: 80%;
`;

const Spacer = styled.div`
  height: 8em;
`;

const Testimonials = props => {
  const { onCtaClick } = props;

  return (
    <LayoutSection center height="48em" animated bgColor="lightest">
      <Image
        filename="icons/Suite.png"
        style={{ width: "10em", margin: "0 auto 1em" }}
        alt="Boxy Suite 2"
      />
      <H3 style={{ marginBottom: ".6em", fontSize: "2.2em" }}>Try Boxy Suite 2 Today</H3>
      <Button solid color="accent1" wide onClick={onCtaClick}>
        Try for Free
      </Button>
      {/*<Spacer />*/}
      <H3 color="#313442" style={{ marginBottom: ".2em", marginTop: ".4em" }}>
        2 weeks completely free.  Cancel anytime.  No charge until trial ends.
      </H3>
      {/*<a href="https://superlinear.co" target="_blank">*/}
      {/*  <Superlinear src={superlinarLogo} />*/}
      {/*</a>*/}
    </LayoutSection>
  );
};

export default Testimonials;
