import React from "react";
import styled from "styled-components";
import colors from "../components/style/colors";
import LayoutSection from "../components/layout/LayoutSection";
import H2 from "../components/typography/H2";
import P1 from "../components/typography/P1";
import Image from "../components/image";

const ChromeWrapper = styled.div`
  position: relative;
  z-index: -1;
  width: 100%;
  height: 0;
  padding-bottom: 11%;
  @media (max-width: 640px) {
    padding-bottom: 15%;
  }
  .gatsby-image-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 120%;
    min-width: 60em;
    /* height: 100%; */
    box-shadow: 0px 16px 65px -12px rgba(0, 0, 0, 0.15);
    border-radius: 1em;
  }
`;

const Spacer = styled.div`
  margin-bottom: 2em;
  @media (max-width: 1000px) {
    margin-bottom: 0;
  }
`;

const KillTheTabs = props => {
  return (
    <LayoutSection overflow="hidden" animated>
      <H2>
        <span style={{ color: colors.accent2 }}>Simplify Gmail - Kill The Tabs</span>
        <br />
        Become more productive.
      </H2>
      <P1>
        Your emails, notes and calendars deserve a dedicated, focused environment to make you more
        productive, without distractions and stress. This is why we’ve created the best Mac app for
        Gmail, Google Calendar, Google Keep and Google Contacts.
      </P1>
      <ChromeWrapper>
        <Image filename="chrome.png" alt="Google Chrome tabs" />
        {/* <img src={chromeImage} /> */}
      </ChromeWrapper>
      <Spacer />
    </LayoutSection>
  );
};

export default KillTheTabs;
