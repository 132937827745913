import React, { useState, useEffect } from "react";
import styled from "styled-components";

import AllAccounts from "../../assets/feature-glyphs/allAccounts.svg";
import Bucket from "../../assets/feature-glyphs/bucket.svg";
import DarkMode from "../../assets/feature-glyphs/darkMode.svg";
import Checkmark from "../../assets/feature-glyphs/checkmark.svg";
import PaintBrush from "../../assets/feature-glyphs/paintBrush.svg";
import DockDate from "../../assets/feature-glyphs/dockDate.svg";
import Sparks from "../../assets/feature-glyphs/sparks.svg";
import Hammer from "../../assets/feature-glyphs/hammer.svg";
import Box from "../../assets/feature-glyphs/box.svg";
import GSuite from "../../assets/feature-glyphs/GSuite.svg";
import Shortcuts from "../../assets/feature-glyphs/shortcuts.svg";
import Rocket from "../../assets/feature-glyphs/rocket.svg";
import Tracking from "../../assets/feature-glyphs/tracking.svg";
import Calendar from "../../assets/feature-glyphs/calendar.svg";
import Leaf from "../../assets/feature-glyphs/leaf.svg";
import Diamond from "../../assets/feature-glyphs/diamond.svg";
import Bell from "../../assets/feature-glyphs/bell.svg";
import ReaderMode from "../../assets/feature-glyphs/readerMode.svg";
import Search from "../../assets/feature-glyphs/search.svg";
import UpToDate from "../../assets/feature-glyphs/upToDate.svg";
import StatusMenuIcon from "../../assets/feature-glyphs/status.svg";
import MinimalModeIcon from "../../assets/feature-glyphs/minimal.svg";
import LauncherIcon from "../../assets/feature-glyphs/launcher.svg";
import ComposerIcon from "../../assets/feature-glyphs/composer.svg";
import LightningIcon from "../../assets/feature-glyphs/lightning.svg";

const featureGlyphs = {
  dockDate: <DockDate />,
  paintBrush: <PaintBrush />,
  bucket: <Bucket />,
  diamond: <Diamond />,
  leaf: <Leaf />,
  GSuite: <GSuite />,
  box: <Box />,
  sparks: <Sparks />,
  upToDate: <UpToDate />,
  readerMode: <ReaderMode />,
  shortcuts: <Shortcuts />,
  calendar: <Calendar />,
  rocket: <Rocket />,
  tracking: <Tracking />,
  checkmark: <Checkmark />,
  allAccounts: <AllAccounts />,
  hammer: <Hammer />,
  bell: <Bell />,
  search: <Search />,
  darkMode: <DarkMode />,
  status: <StatusMenuIcon />,
  minimal: <MinimalModeIcon />,
  launcher: <LauncherIcon />,
  composer: <ComposerIcon />,
  lightning: <LightningIcon />
};

function randomColor() {
  const hues = [50, 300, 360, 110, 160, 190, 230, 270];
  let index = Math.round(Math.random() * (hues.length - 1));
  let saturation = Math.round(Math.random() * 23) + 30;
  return {
    foreground: `hsl(${hues[index]}, ${saturation}%, 60%)`,
    background: `hsla(${hues[index]}, ${saturation}%, 60%, .15)`
  };
}

const GlyphWrapper = styled.div`
  position: relative;
  background: ${props => props.color.background};
  width: 5em;
  height: 5em;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  svg {
    width: 3.2em;
    height: 3.2em;
    path,
    rect {
      fill: ${props => props.color.foreground};
    }
  }
  @media (max-width: 640px) {
    font-size: 0.8em;
  }
  span {
    top: -0.3em;
    left: 3.9em;
    line-height: 1.4;
    position: absolute;
    padding: 0.1em 0.4em;
    font-size: 0.95em;
    font-weight: 700;
    color: white;
    background: rgb(240, 30, 20);
    border-radius: 0.3em;
    transition: 0.3s 0.5s cubic-bezier(0.3, 0, 0.2, 1.3);
    ${props =>
      !props.featureVisible &&
      `
      opacity: 0;
      transform: translateY(1em);
    `}
  }
`;

const FeatureGlyph = props => {
  const { id, newFeature, featureVisible } = props;

  const [color, setColor] = useState(120);

  useEffect(() => {
    setColor(randomColor());
  }, []);

  return (
    <GlyphWrapper color={color} featureVisible={featureVisible}>
      {/* {newFeature && <span>NEW</span>} */}
      {featureGlyphs[id]}
    </GlyphWrapper>
  );
};

export default FeatureGlyph;
