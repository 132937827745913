import React from "react";
import styled from "styled-components";
import LayoutSection from "../components/layout/LayoutSection";
import Grid from "../components/layout/Grid";
import P1 from "../components/typography/P1";
import H2 from "../components/typography/H2";
import H3 from "../components/typography/H3";
import Image from "../components/image";

const Logo = styled.img`
  width: 10em;
`;

const Superlinear = styled.img`
  width: 24em;
  max-width: 80%;
`;

const Spacer = styled.div`
  height: 4em;
`;

const KeepContactsSection = props => {
  return (
    <LayoutSection center height="48em" animated small>
      <H2>The family got bigger</H2>
      <P1>
        Now including beautiful clients for Google Keep and Google Contacts, with Dark Mode support
        and deep integration with the rest of the Suite.
      </P1>
      <Spacer />
      <Grid columns="1fr 1fr" style={{ maxWidth: "44em", margin: "0 auto" }} break="640px">
        <div>
          <Image
            filename="icons/Keep"
            style={{ width: "9em", margin: "0 auto 1em" }}
            alt="Boxy for Keep"
          />
          <H3 style={{ marginBottom: ".6em" }}>Keep</H3>
        </div>
        <div>
          <Image
            filename="icons/Contacts"
            style={{ width: "9em", margin: "0 auto 1em" }}
            alt="Boxy for Contacts"
          />
          <H3 style={{ marginBottom: ".6em" }}>Contacts</H3>
        </div>
      </Grid>
    </LayoutSection>
  );
};

export default KeepContactsSection;
