import React, { useState } from "react";
import styled from "styled-components";
import Image from "../components/image";
import LayoutSection from "../components/layout/LayoutSection";
import H2 from "../components/typography/H2";
import P1 from "../components/typography/P1";

const AccountMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    width: 50%;
    max-width: 36em;
    height: auto;
    :first-child {
      left: 0;
      @media (max-width: 1100px) {
        left: 10%;
        width: 63%;
        max-width: unset;
        height: auto;
      }
      @media (max-width: 700px) {
        left: -28%;
        width: 120%;
        max-width: unset;
        height: auto;
      }
    }
    :last-child {
      right: 0;
      z-index: -2;
      @media (max-width: 1100px) {
        display: none;
      }
    }
  }
`;

const TextWrapper = styled.div`
  transform-origin: 50% 0%;
  transition: 0.7s cubic-bezier(0.3, 0, 0.2, 1);
  ${props =>
    !props.show &&
    `
      opacity: 0;
      transform: scale(.9) translateY(2em);
  `}
`;

const AccountMenuSection = props => {
  const [textVisibility, setTextVisibility] = useState(false);

  return (
    <LayoutSection
      center
      bgColor="black"
      style={{ padding: "18em 0 9em" }}
      visibilityChange={visibility => {
        setTextVisibility(visibility);
      }}
    >
      <AccountMenu>
        <Image filename="account-menu/account-menu-left.png" alt="Boxy Suite Account Menu" />
        <Image filename="account-menu/account-menu-right.png" alt="Boxy Suite Account Menu" />
      </AccountMenu>
      <TextWrapper show={textVisibility}>
        <H2 color="white">All your accounts at your fingertips</H2>
        <P1 color="grey">
          One of the things that our users love the most is how convenient it is to have all your
          accounts in the same place, and access them quickly with keyboard shortcuts.
        </P1>
      </TextWrapper>
    </LayoutSection>
  );
};

export default AccountMenuSection;
