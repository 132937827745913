import React from "react";
import Carousel from "../components/layout/Carousel";
import LayoutSection from "../components/layout/LayoutSection";
import TestimonialCard from "../components/testimonialCard";

const testimonials = [
  {
    name: "Jorn van Dijk",
    username: "jornvandijk",
    company: "Framer",
    quote:
      "Boxy Suite is the best way to use Gmail and Google Calendar on your Mac. I use it every day and love it."
  },
  {
    name: "Ryan Kulp",
    username: "ryanckulp",
    company: "FOMO",
    quote:
      "Just downloaded Boxy Suite and already in love. Killed ~13 permanently open tabs with their suite of native clients."
  },
  {
    name: "Chris Messina",
    username: "chrismessina",
    company: "Hashtag Inventor, Product Designer",
    quote:
      "Boxy Suite is a beautiful and highly functional way to free the power of G Suite from the browser and use it as a set of finely integrated native apps. Highly recommended."
  },
  {
    name: "Zee M Kane",
    username: "Zee",
    company: "Media/Tech Entrepreneur",
    quote:
      "Boxy makes G Suite beautiful and gives it those extra priceless features that should have been there from the start."
  }
];

const Testimonials = () => {
  return (
    <LayoutSection style={{ padding: "0" }}>
      <Carousel>
        {testimonials.map((t, index) => (
          <TestimonialCard
            key={index}
            name={t.name}
            username={t.username}
            company={t.company}
            quote={t.quote}
          />
        ))}
      </Carousel>
    </LayoutSection>
  );
};

export default Testimonials;
