import React, { useEffect, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Paywall from "../components/Paywall";
import SEO from "../components/seo";
import PopupWrapper from "../components/ui/popupWrapper";
import "../main.css";
import AccountMenuSection from "../sections/AccountMenuSection";
import DashboardSection from "../sections/DashboardSection";
import Hero from "../sections/Hero";
import KeepContactsSection from "../sections/KeepContactsSection";
import KillTheTabs from "../sections/KillTheTabs";
import ProductSection from "../sections/ProductSection";
import Testimonials from "../sections/Testimonials";
import TryTodaySection from "../sections/TryTodaySection";
import paywallUtils from "../utils/paywallUtils";
import track from "../utils/track";
import { Helmet } from "react-helmet";
import ExitPopup from "../components/ExitPopup";
import SchemaOrg from "../data/schema_org.json";
import Companies from "../sections/Companies";
import Confetti from "react-dom-confetti";
import colors from "../components/style/colors";
import TopBanner from "../components/topBanner";
import TopBannerSpecial from "../components/TopBannerSpecial";
import getUrlParameter from "../utils/getUrlParameter";
import RoiCalculator from "../sections/RoiCalculator";
import OtherCompanies from "../sections/OtherCompany";

const confettiConfig = {
  angle: "95",
  spread: "200",
  startVelocity: "120",
  elementCount: 200,
  dragFriction: "0.3",
  duration: "6000",
  delay: "1",
  width: "10px",
  height: "10px",
  colors: [colors.specialCoupon, colors.specialCouponAccent, colors.coupon, colors.dark]
};

const IndexPage = props => {
  useEffect(() => {
    const defaultOpenPaywall = getUrlParameter("tryit", false);
    if (defaultOpenPaywall) {
      setPaywallVisibility(true);
    }
  }, []);

  const [paywallVisibility, setPaywallVisibility] = useState(false);
  const [paywallOpened, setPaywallOpened] = useState(false);

  const [couponCode, setCouponCode] = useState(null);
  const [coupon, setCoupon] = useState({
    active: false,
    discount: null,
    text: "...",
    expiration: null
  });

  const [confettiActive, setConfettiActive] = useState(false);

  useEffect(() => {
    setCouponCode(paywallUtils.readCoupon());
  }, []);

  useEffect(() => {
    if (paywallVisibility && !paywallOpened) {
      track.initiate();
      setPaywallOpened(true);
    }
  }, [paywallVisibility]);

  useEffect(() => {
    if (couponCode !== null && couponCode !== "") {
      paywallUtils.fetchCoupon(couponCode, data => {
        let isExpired = paywallUtils.checkExpiration(data.expires);
        !isExpired && couponCode.includes("black-friday") && setConfettiActive(true);
        setCoupon({
          active: !isExpired,
          discount: data.discount,
          text: `${(data.discount / 10).toFixed() * 10}% OFF`,
          expiration: data.expires
        });
      });
    }
  }, [couponCode]);

  return (
    <ParallaxProvider>
      <SEO title="Boxy Suite 2 - The best Gmail app for Mac" canonical={props.location.href} />
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify(SchemaOrg)
          }
        ]}
      />
      <CookieBanner />
      {coupon.active ? (
        couponCode.includes("black-friday") ? (
          <TopBannerSpecial
            data={coupon}
            onClick={() => {
              setPaywallVisibility(true);
            }}
          />
        ) : (
          <TopBanner
            data={coupon}
            onClick={() => {
              setPaywallVisibility(true);
            }}
          />
        )
      ) : (
        ""
      )}

      <div style={{ position: "relative", left: "50%" }}>
        <Confetti active={confettiActive} config={confettiConfig} />
      </div>
      <LayoutWrapper>
        <PopupWrapper
          show={paywallVisibility}
          onOutsideClick={() => {
            // setPaywallVisibility(false);
          }}
        >
          <Paywall
            show={paywallVisibility}
            setPaywallVisibility={setPaywallVisibility}
            coupon={coupon.active && coupon}
            setCoupon={setCoupon}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
          />
        </PopupWrapper>
        <Hero
          onCtaClick={() => {
            setPaywallVisibility(true);
          }}
        />
        <Companies />
        <OtherCompanies />

        <KillTheTabs />
        <Testimonials />

        <ProductSection
          app="gmail"
          onCtaClick={() => {
            setPaywallVisibility(true);
          }}
        />
        <AccountMenuSection />
        <ProductSection
          app="calendar"
          onCtaClick={() => {
            setPaywallVisibility(true);
          }}
        />
        <KeepContactsSection />
        <TryTodaySection
          onCtaClick={() => {
            setPaywallVisibility(true);
          }}
        />

        <Footer legal />
      </LayoutWrapper>
    </ParallaxProvider>
  );
};

export default IndexPage;
