import React, { useEffect } from "react";
import styled from "styled-components";
import colors from "../style/colors";
import PrevIcon from "../../../assets/glyphs/prev.svg";
import NextIcon from "../../../assets/glyphs/next.svg";

const CarouselWrapper = styled.div`
  max-width: 100%;
  width: auto;
  display: grid;
  grid-template-columns: 2em 1fr 2em;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 0.8em;
    height: auto;
    path {
      stroke: ${colors.accent1_light};
    }
  }
`;

const CarouselView = styled.div`
  /* width: 100%; */
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: stretch;
  > * {
    min-width: 100%;
    flex: 1 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: normal;
  }
`;

const CarouselControls = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0.6em 0 0;
  li {
    display: inline-block;
    padding: 0.6em 0.4em;
    cursor: pointer;
    > div {
      width: 8px;
      height: 8px;
      background: ${colors.light};
      border-radius: 50%;
    }
  }
  li.current {
    > div {
      background: ${colors.dark};
    }
  }
`;

let carouselWidth = 0;
let currentPage = 0;
let pages = 0;

const Carousel = props => {
  pages = props.children.length;

  useEffect(() => {
    carouselScroll();
  }, []);

  return (
    <div style={{ margin: "1.5em auto 1em", maxWidth: "100%" }}>
      <CarouselWrapper>
        <ArrowWrapper
          onClick={() => {
            prevPage();
          }}
        >
          <PrevIcon />
        </ArrowWrapper>
        <CarouselView id="carousel">
          {props.children.map((child, index) => (
            <div key={index}>{child}</div>
          ))}
        </CarouselView>
        <ArrowWrapper
          onClick={() => {
            nextPage();
          }}
        >
          <NextIcon />
        </ArrowWrapper>
      </CarouselWrapper>
      <CarouselControls id="carousel-controls">
        {props.children.map((child, index) => (
          <li
            key={index}
            index={index}
            onClick={() => {
              goToPage(index);
            }}
          >
            <div />
          </li>
        ))}
      </CarouselControls>
    </div>
  );
};

export default Carousel;

function carouselScroll() {
  carouselWidth = document.querySelector("#carousel").offsetWidth;
  goToPage(0);
}

function nextPage() {
  if (currentPage != pages - 1) {
    goToPage(currentPage + 1);
  } else {
    goToPage(0);
  }
}

function prevPage() {
  if (currentPage != 0) {
    goToPage(currentPage - 1);
  } else {
    goToPage(pages - 1);
  }
}

function goToPage(page) {
  currentPage = page;
  let scrollValue = carouselWidth * page;
  scrollTo("#carousel", scrollValue, 400);
  if (document.querySelector(".current")) {
    document.querySelector(".current").classList.remove("current");
  }
  document.querySelectorAll("#carousel-controls li")[page].classList.add("current");
}

function scrollTo(element, to, duration) {
  var start = document.querySelector(element).scrollLeft,
    change = to - start,
    currentTime = 0,
    increment = 20;

  var animateScroll = function() {
    const el = document.querySelector(element);
    if (el) {
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      document.querySelector(element).scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }
  };
  animateScroll();
}

Math.easeInOutQuad = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
