import React from "react";
import styled from "styled-components";
import colors from "../components/style/colors";
import SiteHeader from "../components/SiteHeader";
import LayoutSection from "../components/layout/LayoutSection";
import H1 from "../components/typography/H1";
import P1 from "../components/typography/P1";
import P2 from "../components/typography/P2";
import Button from "../components/ui/Button";
import Grid from "../components/layout/Grid";
import Image from "../components/image";
import scrollTo from 'gatsby-plugin-smoothscroll';

const HeroWrapper = styled.div`
  position: relative;
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(${colors.accent1_gradient_dark});
    mask: url("/masks/mask01.svg");
    mask-size: 100% 100%;
    mask-position: top left;
  }
`;

const ScreensWrapper = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 70%;
  .gatsby-image-wrapper {
    position: absolute;
    box-shadow: 0px 10px 60px -10px rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
    @media (max-width: 640px) {
      border-radius: 0.3em;
    }
  }
  .gatsby-image-wrapper:first-child {
    width: 85%;
    top: 0;
    left: 0;
  }
  .gatsby-image-wrapper:last-child {
    width: 80%;
    right: 0;
    top: 6em;
  }
  @media (max-width: 900px) {
    margin-right: -8em;
    margin-left: -8em;
    padding-bottom: 115%;
    .gatsby-image-wrapper {
      :first-child {
        left: -2em;
      }
      :last-child {
        right: -3em;
      }
    }
  }
`;

const HeaderWrapper = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
  @media (max-width: 1150px) {
    h1,
    p {
      max-width: 55rem;
    }
    margin-top: 3em;
    margin-bottom: 8em;
  }
  @media (max-width: 640px) {
    margin-top: 0em;
    margin-bottom: 5em;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: -1;
  .gatsby-image-wrapper {
    position: relative;
    margin-right: -2em;
    /* position: absolute; */
    /* left: -4em; */
    /* bottom: -1em; */
    /* width: 100%; */
    /* height: auto; */
  }
  @media (max-width: 1150px) {
    /* padding-bottom: 30%; */
    .gatsby-image-wrapper {
      /* position: relative; */
      /* left: 0; */
      /* bottom: 0; */
      /* width: 90%; */
      max-width: 35em;
      /* margin: 2em auto; */
      /* padding: 2em 0; */
      right: 1.2em;
      margin: -1em auto -4em auto;
    }
  }
`;

const NewBanner = styled.a`
  text-decoration: none;
  text-align: center;
  display: block;
  font-size: 0.9em;
  div {
    margin: 0 auto 2.5em;
    display: inline-block;
    padding: 0.2em;
    @media (max-width: 640px) {
      padding: 0.4em 0 0.4em 0.4em;
    }
    color: white;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: inline-flex;
    align-items: center;
    border-radius: 2em;
    span {
      background: rgb(20, 150, 50);
      padding: 0.2em 0.6em;
      font-weight: 600;
      margin-right: 0.7em;
      font-size: 0.9em;
      letter-spacing: 1.5px;
      border-radius: 2em;
      @media (max-width: 640px) {
        font-size: 0.8em;
      }
    }
    p {
      display: inline-block;
      margin: 0;
      opacity: 0.8;
      padding-right: 0.5em;
      text-align: left;
    }
    :hover {
      background: rgba(0, 0, 0, 0.3);
      p {
        opacity: 1;
      }
    }
  }
`;
const Btnstyle=styled.button`
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 16px;
  border: none;
  outline: none;
  // background-color: red;
  color: white;
  cursor: pointer;
  padding: 2px;
  border-radius: 10px;
`;
const Hero = props => {
  const { onCtaClick } = props;

  return (
    <HeroWrapper>
      <div id={"scrollhere"}></div>

      <SiteHeader onTryForFreeClick={onCtaClick} />
      <LayoutSection
        overflow="hidden"
        style={{
          paddingTop: "0em",
          paddingBottom: "10em",
          marginBottom: "-6em"
        }}
        centerAt="1150px"
      >
        <HeaderWrapper>
          {/* <NewBanner href="/blog/gmail-guide">
            <div>
              <span>NEW</span>
              <p>Boost your Gmail productivity with our new guide!</p>
            </div>
          </NewBanner> */}
          <Btnstyle onClick={() => scrollTo('#scrollhere')} id={"btnscroll"}><img src="https://boxysuite.s3-us-west-1.amazonaws.com/assets/up.webp" width="31px" height= "31px"></img></Btnstyle>
          <Grid columns="25em 1fr" break="1150px">
            <div>
              <H1>The best Gmail app for Mac</H1>
              <P1 color="white">Become faster and more productive with Gmail, Calendar, Keep, and Contacts.</P1>
              <div
                style={{
                  display: "inline-flex",
                  placeItems: "center",
                  flexDirection: "column"
                }}
              >
                <Button wide solid onClick={onCtaClick}>
                  Try for Free
                </Button>
                <span
                  style={{
                    marginTop: "0.4em",
                    color: "white",
                    fontSize: "1.2em",
                    display: "block",
                    textAlign: "center"
                  }}
                >
                  Join 2,200+ users.
                </span>
                <span
                  style={{
                    marginTop: "0.4em",
                    color: "white",
                    fontSize: "1.2em",
                    display: "block",
                    textAlign: "center",
                    fontWeight:600
                  }}
                >
                  No risk, Cancel Anytime
                </span>
              </div>
            </div>
            <ImageWrapper>
               {/*<img src={dockImage} alt="" />*/}
              <Image filename="hero/newhero" alt="Boxy Suite on your Dock" />
            </ImageWrapper>
          </Grid>
        </HeaderWrapper>
        <ScreensWrapper>
          <Image filename="Calendar - Sidebar Closed (Normal)" alt="Boxy for Calendar" />
          <Image filename="Gmail - Minimal - Open" alt="Boxy for Gmail" />
        </ScreensWrapper>
      </LayoutSection>
    </HeroWrapper>
  );
};

export default Hero;
