import React, { useState } from "react";
import styled from "styled-components";
import LayoutSection from "../components/layout/LayoutSection";
import H2 from "../components/typography/H2";
import P1 from "../components/typography/P1";
import Button from "../components/ui/Button";
import dashboardIcon from "../images/icons/Dashboard.png";
import Grid from "../components/layout/Grid";
import productsData from "../data/products.json";
import featuresData from "../data/features.json";
import topMenuLeft from "../images/dashboard/top-menu-left.png";
import topMenuRight from "../images/dashboard/top-menu-right.png";
import AppFeature from "../components/AppFeature";
import Image from "../components/image";

const apps = {
  dashboard: {
    icon: dashboardIcon,
    accent: "accent1"
  }
};

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12em;
  .gatsby-image-wrapper {
    width: 7em;
    position: relative;
    margin-bottom: 1em;
  }
  @media (max-width: 1100px) {
    padding: 4em 0;
    .gatsby-image-wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const StickyWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  @media (max-width: 1100px) {
    position: relative;
    height: auto;
  }
`;

const TopMenu = styled.div`
  width: 100%;
  position: sticky;
  height: 80vh;
  /* background: black; */
  top: 0;
  z-index: 0;
  :before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 1.4em;
    background: black;
    z-index: -1;
  }
  img {
    position: absolute;
    top: 0;
    width: auto;
    height: 1.4em;
    z-index: 2;
    :first-child {
      left: 0;
    }
    :last-child {
      right: 0;
    }
  }
  @media (max-width: 1100px) {
    display: none;
  }
`;

const Dashboard = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  height: 80vh;
  .gatsby-image-wrapper {
    position: absolute;
    right: -1.16em;
    width: 38em;
    transform-origin: 50% 0%;
    transition: 0.7s cubic-bezier(0.3, 0, 0.2, 1);
  }
  @media (max-width: 1100px) {
    position: relative;
    height: auto;
    text-align: center;
    margin-top: -2em;
    .gatsby-image-wrapper {
      position: relative;
      height: auto;
      max-width: 100%;
      right: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${props =>
    !props.show &&
    `
    .gatsby-image-wrapper {
      opacity: 0;
      transform: scale(.9);
    }
  `}
`;

const DashboardSection = props => {
  const { onCtaClick } = props;

  const [dashboardVisibility, setDashboardVisibility] = useState(null);

  return (
    <LayoutSection
      style={{ paddingBottom: "10em" }}
      bgColor="black"
      centerAt="1100px"
      visibilityChange={visibility => {
        setDashboardVisibility(visibility);
      }}
    >
      <StickyWrapper>
        <TopMenu>
          <img src={topMenuLeft} />
          <img src={topMenuRight} />
        </TopMenu>
      </StickyWrapper>
      <StickyWrapper>
        <Dashboard show={dashboardVisibility}>
          <Image filename="dashboard/Dashboard.png" alt="Boxy Suite Dashboard" />
        </Dashboard>
      </StickyWrapper>
      <Grid columns="30em 1fr" gap="4em" break="1100px">
        <div>
          <ProductHeader>
            <div>
              <Image filename="icons/Dashboard" alt="Boxy Suite Dashboard" />
              <H2 color="light">{productsData["dashboard"].name}</H2>
              <P1 color="grey">{productsData["dashboard"].description}</P1>
              <Button wide color={apps["dashboard"].accent} solid onClick={onCtaClick}>
                Try For Free
              </Button>
            </div>
          </ProductHeader>
          {featuresData["dashboard"].map((f, index) => (
            <AppFeature
              inverted
              title={f.title}
              body={f.body}
              glyph={f.glyph}
              key={index}
              newFeature={f.new}
              breakpoint="1100px"
            />
          ))}
        </div>
        <div />
      </Grid>
    </LayoutSection>
  );
};

export default DashboardSection;
