import React, { useEffect, useState } from "react";
import styled from "styled-components";
import P1 from "./typography/P1";
import Button from "./ui/Button";
import colors from "./style/colors";
import CouponIcon from "../../assets/glyphs/birthday.svg";
import Countdown from "react-countdown-now";
import paywallUtils from "../utils/paywallUtils";

const CouponIconWrapper = styled.div`
  margin-right: 1em;
  bottom: -0.1em;
  position: relative;
  svg {
    width: 2em;
    height: 2em;
    path {
      fill: ${colors.specialCoupon};
    }
  }
`;

const Bannerwrapper = styled.div`
  width: 100%;
  padding: 1em;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  > p {
    color: ${colors.specialCoupon};
    margin: 0;
  }
  ${props =>
    props.disabled &&
    `
      display: none;
    `}
  @media (max-width: 640px) {
    flex-direction: column;
    padding-bottom: 1.7em;
  }
`;

const TopBannerSpecial = props => {
  const { url, onClick, dismiss } = props;

  const { text, expiration } = props.data;

  let expirationDate = null;
  let daysUntilExpiration = null;
  if (expiration) {
    expirationDate = paywallUtils.parseCouponDate(expiration);
    let now = new Date();
    daysUntilExpiration = Math.round((expirationDate - now) / (1000 * 60 * 60 * 24));
  }

  const cookie = props.cookie ? `-${props.cookie}` : "";

  const [okClicked, setOkClicked] = useState(false);

  useEffect(() => {
    const okClickedStored = localStorage.getItem(`banner-dismissed${cookie}`);
    if (okClickedStored) {
      setOkClicked(true);
    } else {
      setOkClicked(false);
    }
    if (!dismiss) {
      setOkClicked(false);
    }
  }, []);

  function digits(num) {
    return ("0" + num).slice(-2);
  }

  function rendered(p) {
    if (p.days > 2) {
      return `${p.days} days and ${p.hours} hours`;
    } else if (p.days > 0) {
      return `${p.days * 24 + p.hours} hours and ${p.minutes} minutes`;
    } else {
      return `${digits(p.hours)}:${digits(p.minutes)}:${digits(p.seconds)}`;
    }
  }

  function showExpiration() {
    if (expirationDate && daysUntilExpiration < 30) {
      return (
        <>
          <span> expiring in </span>
          <span style={{ fontVariantNumeric: "tabular-nums" }}>
            <Countdown date={expirationDate} daysInHours={true} renderer={rendered} />
          </span>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <Bannerwrapper disabled={okClicked}>
      <CouponIconWrapper>
        <CouponIcon style={{ margin: ".6em .5em" }} />
      </CouponIconWrapper>
      <P1 center style={{ margin: ".3em 0" }}>
        It's Black Friday!{" "}
        <em style={{ color: colors.specialCouponAccent, fontWeight: 600, fontStyle: "normal" }}>
          {text}
        </em>
        {showExpiration()}
      </P1>
      <Button
        style={{ margin: ".6em 1.6em", fontSize: ".9em", flexShrink: 0 }}
        color="specialCouponAccent"
        solidInverted
        href={url}
        onClick={() => {
          if (dismiss) {
            setOkClicked(true);
            localStorage.setItem(`banner-dismissed${cookie}`, "true");
          }
          onClick();
        }}
      >
        Get it!
      </Button>
    </Bannerwrapper>
  );
};

export default TopBannerSpecial;
