import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import AppFeature from "../components/AppFeature";
import Image from "../components/image";
import LayoutSection from "../components/layout/LayoutSection";
import ProductCarousel from "../components/layout/ProductCarousel";
import H2 from "../components/typography/H2";
import P1 from "../components/typography/P1";
import Button from "../components/ui/Button";
import featuresData from "../data/features.json";
import productsData from "../data/products.json";
import calendarIcon from "../images/icons/Calendar.png";
import dashboardIcon from "../images/icons/Dashboard.png";
import gmailIcon from "../images/icons/Gmail.png";

const apps = {
  gmail: {
    icon: "icons/Gmail",
    accent: "accent2",
    screens: [
      "app-screens/gmail/Gmail - Normal - Open.png",
      "app-screens/gmail/Gmail - Minimal - Open.png",
      "app-screens/gmail/Gmail - Black Minimal - Open.png",
      "app-screens/gmail/Gmail - Quick Launcher 1.png",
      "app-screens/gmail/Gmail - Open Email (Tracked).png",
      "app-screens/gmail/Gmail - Quick Launcher 2.png",
      "app-screens/gmail/Gmail - Quick Launcher 4.png",
      "app-screens/gmail/Gmail - Open Email.png",
      "app-screens/gmail/Gmail - Open Email (Reader Mode).png",
      "app-screens/gmail/Gmail - Search 2.png",
      "app-screens/gmail/Gmail - Minimal - Closed.png",
      "app-screens/gmail/Gmail - Compose.png",
      "app-screens/gmail/Gmail - RSVP.png"
    ]
  },
  calendar: {
    icon: "icons/Calendar",
    accent: "accent3",
    screens: [
      "app-screens/calendar/Calendar.png",
      "app-screens/calendar/Calendar (Black Theme).png",
      "app-screens/calendar/Calendar - 4 Days Sidebar Closed.png",
      "app-screens/calendar/Calendar - Month.png",
      "app-screens/calendar/Calendar - Smaller.png",
      "app-screens/calendar/Calendar - Event Detail.png",
      "app-screens/calendar/Calendar - RSVP.png"
    ]
  },
  dashboard: {
    icon: dashboardIcon,
    accent: "accent1",
    screens: []
  }
};

const ProductGrid = styled.div`
  display: grid;
  min-height: auto;
  grid-template-columns: 30em 1fr;
  grid-gap: 4em;
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    > div {
      :first-child {
        order: 2;
      }
      :last-child {
        order: 1;
      }
    }
  }
  overflow: visible;
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12em;
  height: calc(100vh - 12em);
  min-height: 26em;
  .gatsby-image-wrapper {
    width: 7em;
    position: relative;
    margin-bottom: 1em;
  }
  @media (max-width: 960px) {
    padding: 3em 0;
    height: auto;
    min-height: auto;
    .gatsby-image-wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const ProductSection = props => {
  const { app, theme, onCtaClick } = props;

  const [forcedPage, setForcedPage] = useState(0);

  return (
    <LayoutSection
      style={{ paddingTop: "2em", paddingBottom: "10em" }}
      bgColor={theme == "dark" ? "black" : "white"}
      centerAt="960px"
      animated
    >
      <ProductGrid>
        <div>
          <VisibilitySensor
            onChange={isVisible => {
              if (isVisible) {
                setForcedPage(0);
              }
            }}
            partialVisibility={true}
            offset={{ top: 300 }}
          >
            <ProductHeader>
              <div>
                <Image
                  filename={`icons/${app.slice(0, 1).toUpperCase() + app.slice(1, 99)}`}
                  alt={`${productsData[app].name}`}
                />
                <H2>{productsData[app].name}</H2>
                <P1>{productsData[app].description}</P1>
                <Button solid wide color={apps[app].accent} onClick={onCtaClick}>
                  Try For Free
                </Button>
              </div>
            </ProductHeader>
          </VisibilitySensor>
          {featuresData[app].map((f, index) => (
            <AppFeature
              id={index}
              title={f.title}
              body={f.body}
              glyph={f.glyph}
              key={index}
              newFeature={f.new}
              onScreenCallback={index => {
                setForcedPage(index);
              }}
            />
          ))}
        </div>
        <div>
          <ProductCarousel forcedPage={forcedPage}>
            {apps[app].screens.map((s, i) => {
              return (
                <Image key={i} filename={s} alt={`${productsData[app].name} app screenshot ${i}`} />
              );
            })}
          </ProductCarousel>
        </div>
      </ProductGrid>
    </LayoutSection>
  );
};

export default ProductSection;
