import React, { useState, useEffect } from "react";
import styled from "styled-components";
import H3 from "./typography/H3";
import P2 from "./typography/P2";
import colors from "./style/colors";
import FeatureGlyph from "./FeatureGlyph";
import VisibilitySensor from "react-visibility-sensor";

const AppFeatureWrapper = styled.div`
  position: relative;
  margin: 15em 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 0em;
    ${props =>
      props.inverted &&
      `
      color: ${colors.light};
    `}
  }
  p {
    ${props =>
      props.inverted &&
      `
      color: ${colors.grey};
    `}
  }
  @media (max-width: ${props => props.breakpoint || "960px"}) {
    margin: 4em 0;
  }
  @media (min-width: ${props => props.breakpoint || "960px"}) {
    transition: 0.7s opacity cubic-bezier(0.3, 0, 0.2, 1);
    transform-origin: 0 50%;
    opacity: 0.7;
    ${props =>
      props.featureVisible &&
      `
    opacity: 1;
  `}
  }
`;

function getWindowHeight() {
  if (typeof window !== "undefined") {
    const { innerHeight: height } = window;
    return height;
  } else {
    return null;
  }
}

const Badge = styled.div`
  display: inline-block;
  margin: 0.5em 0;
  line-height: 1.4;
  padding: 0.1em 0.4em;
  font-size: 0.95em;
  font-weight: 700;
  color: white;
  background: ${colors.accent2};
  border-radius: 0.3em;
  transition: 0.3s 0.3s cubic-bezier(0.3, 0, 0.2, 1.3);
  ${props =>
    !props.featureVisible &&
    `
    opacity: 0;
    transform: translateY(1em);
  `}
`;

const AppFeature = props => {
  const { id, title, body, glyph, inverted, newFeature, onScreenCallback, breakpoint } = props;

  const [featureVisibility, setFeatureVisibility] = useState(false);
  const [windowHeight, setWindowHeight] = useState(getWindowHeight());
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(windowHeight * 0.4);
  }, []);

  function onChange(isVisible) {
    setFeatureVisibility(isVisible);
  }

  useEffect(() => {
    if (typeof onScreenCallback === "function") {
      if (featureVisibility) {
        onScreenCallback(id + 1);
      }
    }
  }, [featureVisibility]);

  return (
    <VisibilitySensor
      onChange={onChange}
      partialVisibility={true}
      offset={{ top: offset, bottom: offset }}
    >
      <AppFeatureWrapper
        inverted={inverted}
        featureVisible={featureVisibility}
        breakpoint={breakpoint}
      >
        <FeatureGlyph id={glyph} newFeature={newFeature} featureVisible={featureVisibility} />
        <H3>{title}</H3>
        <P2>{body}</P2>
        {newFeature && <Badge featureVisible={featureVisibility}>New</Badge>}
      </AppFeatureWrapper>
    </VisibilitySensor>
  );
};

export default AppFeature;
