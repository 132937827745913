import React from "react";
import styled from "styled-components";
import colors from "./style/colors";
import P1 from "./typography/P1";
import P2 from "./typography/P2";

import imgChris from "../images/testimonials/chris.jpg";
import imgJorn from "../images/testimonials/jorn.jpg";
import imgZee from "../images/testimonials/zee.jpg";
import imgRyan from "../images/testimonials/ryan.jpg";

const avatars = {
  "Chris Messina": imgChris,
  "Jorn van Dijk": imgJorn,
  "Zee M Kane": imgZee,
  "Ryan Kulp": imgRyan
};

const TestimonialWrapper = styled.a`
  /* background: ${colors.lighter}; */
  width: 100%;
  max-width: 44em;
  font-size: 1.1em;
  p {
    line-height: 1.6;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  border-radius: 0.8em;
  @media (max-width: 640px) {
    font-size: 0.9em;
  }
`;

const Avatar = styled.img`
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  margin-bottom: 0.5em;
`;

const TestimonialCard = props => {
  const { name, username, company, quote } = props;

  avatars;
  avatars[name];

  return (
    <TestimonialWrapper href={`https://twitter.com/${username}`} target="_blank">
      <Avatar src={avatars[name]} />
      <P1 color="ultra_dark" center style={{ fontWeight: 700, marginBottom: ".4em" }}>
        {quote}
      </P1>
      <P2 center color="grey">
        {name}, {company}
      </P2>
    </TestimonialWrapper>
  );
};

export default TestimonialCard;
