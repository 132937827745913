import React from "react";
import styled from "styled-components";
import LayoutSection from "../components/layout/LayoutSection";
import H3 from "../components/typography/H3";
import Basecamp from "../images/companies/basecamp.svg";
import BuzzFeed from "../images/companies/buzzfeed.svg";
import Framer from "../images/companies/farmer-logo.png";
import Gitlab from "../images/companies/gitlab.svg";
import Netflix from "../images/companies/netflix.svg";
import RiotGames from "../images/companies/riotgames.png";
import Salesforce from "../images/companies/salesforce.png";
import Salesforcenew from "../images/companies/logo-salesforce.svg";
import Okta from "../images/companies/okta-seeklogo.com.svg";
import Splunk from "../images/companies/splunk-logo-dark.svg";
import Lyft from "../images/companies/Lyft logo – pink – rgb.png";
import Uber from "../images/companies/uber.svg";
import Zoom from "../images/companies/zoom.svg";
import P1 from "../components/typography/P1";
import P2 from "../components/typography/P2";

const companies = [
  {
    name: "Basecamp",
    image: Basecamp,
    url: "basecamp.com"
  },
  {
    name: "GitLab",
    image: Gitlab,
    url: "gitlab.com"
  },
  {
    name: "Uber",
    image: Uber,
    url: "uber.com"
  },
  {
    name: "Okta",
    image: Okta,
    url: "okta.com"
  },{
    name: "Splunk",
    image: Splunk,
    url: "splunk.com"
  },{
    name: "Lyft",
    image: Lyft,
    url: "lyft.com"
  },
  {
    name: "Zoom",
    image: Zoom,
    url: "zoom.com"
  },
  {
    name: "BuzzFeed",
    image: BuzzFeed,
    url: "buzzfeed.com"
  },
  {
    name: "Netflix",
    image: Netflix,
    url: "netflix.com"
  }/*,
  {
    name: "Riot Games",
    image: RiotGames,
    url: "riotgames.com"
  }*/
];
const companies1 = [

  {
    name: "Framer",
    image: Framer,
    url: "framer.com"
  }

];
const companiessf = [

  {
    name: "SalesForce",
    image: Salesforcenew,
    url: "salesforce.com"
  }

];


const Logo = styled.img`
  display: inline-block;
  margin: 0px 2% -0.4em;
  width: 12%;
  height: auto;
  object-fit: contain;
   @media (max-width: 768px) {
    width: 18%;
  }
   @media (max-width: 480px) {
    width: 28%;
  }
`;
const Logosf = styled.img`
  display: inline-block;
  margin: 0px 2% -0.4em;
  width: 12%;
  height: auto;
  object-fit: contain;
  filter: contrast(1) sepia(2) hue-rotate(170deg) saturate(0.2) !important;

  
   @media (max-width: 768px) {
    width: 18%;
  }
   @media (max-width: 480px) {
    width: 28%;
  }
`;
const Logo1 = styled.img`
    display: inline-block;
    margin: 0px 2% -1.4em;
    width: 15%;
    height: auto;
    object-fit: contain;
    @media (max-width: 768px) {
    width: 25%;
    margin-top:7%;
    margin: 0px 2% -0.4em;
  }
   @media (max-width: 480px) {
    width: 40%;
    margin: 0px 2% 2.6em;
    margin-top: 8%;

  }

`;

const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
 /* @media (max-width: 768px) {
    width: 28%;
  }
   @media (max-width: 480px) {
    width: 11%;
  }*/

  /* max-width: 1100px; */
  margin: 0 auto;
  @media (max-width: 900px) {
    max-width: 600px;
  }
  @media (max-width: 640px) {
    font-size: 0.9em;
  }
  img {
    filter: contrast(0) sepia(1) hue-rotate(170deg) saturate(0.2);
    transition: 0.2s;
  }
  /* :hover {
    img {
      filter: contrast(0) sepia(0) hue-rotate(0deg) saturate(0.2);
    }
  } */
  img:hover {
    opacity: 1;
    filter: none !important;
  }
`;

const Companies = () => {
  return (
    <LayoutSection overflow="hidden" animated>
      <H3 center style={{ marginBottom: ".2em" }}>
        Used by the best
      </H3>
      <P2 center style={{ marginBottom: "2.5em" }}>
        We're proud to have people from these incredible companies among our users.
      </P2>
      <Grid>
        {companies.map((c, i) => (
          <Logo key={i} src={c.image} alt={c.name} />
        ))}
        {companiessf.map((c, i) => (
          <Logosf key={i} src={c.image} alt={c.name} />
        ))}

        {companies1.map((c, i) => (
          <Logo1 key={i} src={c.image} alt={c.name} />
        ))}
      </Grid>
    </LayoutSection>
  );
};

export default Companies;
