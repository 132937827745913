import React from "react";
import styled from "styled-components";
import LayoutSection from "../components/layout/LayoutSection";
import H3 from "../components/typography/H3";
import LifeHacker from "../images/company/lifehacker.png";
import Mac from "../images/company/mac.png";
import Muo from "../images/company/muo.png";
import RightBox from "../images/company/rightinbox.png";
import Verge from "../images/company/vergenew.png";
import P2 from "../components/typography/P2";

const companies = [
  {
    name: "rightbox",
    image: RightBox
  },
  {
    name: "verge",
    image: Verge
  }
];
const companies1 = [
  {
    name: "lifehacker",
    image: LifeHacker
  },
  {
    name: "mac",
    image: Mac
  },
  {
    name: "muo",
    image: Muo
  }
];

const Logo = styled.img`
  display: inline-block;
  margin: 0px 2% -0.4em;
  width: 12%;
  height: auto;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 18%;
  }
  @media (max-width: 480px) {
    width: 28%;
  }
`;
const Logosf = styled.img`
  display: inline-block;
  margin: 0px 2% -0.4em;
  width: 12%;
  height: auto;
  object-fit: contain;
  filter: contrast(1) sepia(2) hue-rotate(170deg) saturate(0.2) !important;

  @media (max-width: 768px) {
    width: 18%;
    margin-top: 7%;
    margin: 0px 2% -0.4em;
  }
  @media (max-width: 480px) {
    width: 28%;
    margin: 0px 2% 2.6em;
    margin-top: 8%;
  }
`;
const Logo1 = styled.img`
  display: inline-block;
  margin: 0px 2% -1.4em;
  width: 15%;
  height: auto;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 25%;
    margin-top: 7%;
    margin: 0px 2% -0.4em;
  }
  @media (max-width: 480px) {
    width: 40%;
    margin: 0px 2% 2.6em;
    margin-top: 8%;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* @media (max-width: 768px) {
    width: 28%;
  }
   @media (max-width: 480px) {
    width: 11%;
  }*/

  /* max-width: 1100px; */
  margin: 0 auto;
  @media (max-width: 900px) {
    max-width: 600px;
  }
  @media (max-width: 640px) {
    font-size: 0.9em;
  }
  img {
    filter: contrast(0) sepia(1) hue-rotate(170deg) saturate(0.2);
    transition: 0.2s;
  }
  /* :hover {
    img {
      filter: contrast(0) sepia(0) hue-rotate(0deg) saturate(0.2);
    }
  } */
  img:hover {
    opacity: 1;
    filter: none !important;
  }
`;

const OtherCompanies = () => {
  return (
    <LayoutSection overflow="hidden" animated>
      <H3 center style={{ marginBottom: ".2em" }}>
        As Seen In
      </H3>
      <P2 center style={{ marginBottom: "2.5em" }}>
        Take a look at our press
      </P2>
      <Grid>
        {companies.map((c, i) => (
          <Logo key={i} src={c.image} alt={c.name} />
        ))}
        {companies1.map((c, i) => (
          <Logosf key={i} src={c.image} alt={c.name} />
        ))}
      </Grid>
    </LayoutSection>
  );
};

export default OtherCompanies;
